//
//
//
//
//
//
//

import FiltersSort
    from "@/components/pages/store/StoreFilter/Components/FilterBar/Components/FilterControl/Components/FiltersSort";
import FilterMobileList
    from "@/components/pages/store/StoreFilter/Components/FilterMobile/Components/FilterMobileList/index";
import FilterMobileSort
    from "@/components/pages/store/StoreFilter/Components/FilterMobile/Components/FilterMobileSort/index";
export default {
    name: "FilterMobile",
    components: {FilterMobileSort, FilterMobileList, FiltersSort}
}
