//
//
//
//
//
//
//
//
//
//
//
//

import closeIcon from '~/assets/img/icons/closePopup.svg?raw'
export default {
    name: "FilterMobilePopup",
    props: {
        title: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'filter'
        },
        closeLink: {
            type: String,
            default: '/'
        }
    },
    data() {
        return {
            closeIcon: closeIcon,
        }
    },
    methods: {
        closePopup() {
            this.$emit('closePopup')
        }
    }
}
