//
//
//
//
//
//
//
//

import FiltersListItem
    from "@/components/pages/store/StoreFilter/Components/FilterBar/Components/FiltersList/Components/FiltersListItem/index";
import {mapGetters} from "vuex";
export default {
    name: "FiltersList",
    props: {
        showMore: {
            type: Boolean,
            default: false
        }
    },
    components: {FiltersListItem},
    computed: {
        ...mapGetters({
            filters:  'catalogStore/getFilters',
        }),
    }
}
