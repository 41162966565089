//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import arrow from '~/assets/img/icons/arrow-bottom-small.svg?raw'

export default {
    name: "FiltersSort",
    data() {
        return {
            opened: false,
            arrow: arrow
        }
    },
    computed: {
        ...mapGetters({
            sortRules: 'catalogStore/getSortingLinks',
        }),
        selected() {
            return this.sortRules.find(i => i.selected);
        },
        textBtn() {
            return this.selected && this.selected.title ? this.selected.title : this.$t('store.sorting');
        },
        catalogRoute() {
            return this.$route.path
        }
    },
    methods: {
        closeSelect() {
            this.opened = false;
        },
        toggleSelect() {
            this.opened = !this.opened;
        },
        isSelected(url) {
            return this.selected && this.selected.url === url;
        }
    }
}
